'use strict';

/*
 * ページ内リンクスクロール
 */
$.fn.pageScroll = function(options){
    return $(this).each(function(){
        new $.pageScroll(this, $.extend({
            'position': 0,//停止位置の調整
            'fnc': undefined,//beforeScroll関数
        }, options));
    });
};

$.pageScroll = function(elem, options){
    this.$elem = $(elem);
    this.options = options;
    this.fnc = this.options['fnc'];
    this.afterfnc = this.options['afterfnc'];
    this.href = this.$elem.attr("href");
    this.href = this.href == "#" || this.href == "" ? 'html' : this.href;
    if(this.href.split('#').length !== 1){
        this.href = '#' + this.href.split('#')[1];
    }else if(this.href != 'html'){
        return true;
    }
    this.speed = 500;
    this.setEvent();
};
$.pageScroll.prototype = {
    setEvent: function(){
        var self = this;
        self.$elem.click(function(){
            if(self.fnc !== undefined){
            	self.fnc();
            }
            return self.startScroll();
    });
    },
    startScroll: function(){
        var self = this;
        this.target = $(this.href);
        if(this.target.length > 0){
            this.position = this.target.offset().top + this.options.position;
            $('html, body').animate({scrollTop:this.position}, self.speed, 'swing');
            return false;
        }else{
        	return true;
        }
    }
};

$.fn.fixedElement = function(options){
    return $(this).each(function(){
        new fixedElement(this, $.extend({
            'position': 'bottom',//停止位置の調整
            'fixedelement': 'footer',//停止させたい要素名
            'adjust': { window: 768, num: 10}//調整値
        }, options));
    });
};

class fixedElement{
	constructor(elem, options){
		this._$btn = $(elem);
		this._options = options;
		this._BottomPos = parseInt(this._$btn.css('bottom'));

		$(window).on('scroll', () => {
			this.chkPos();
		});
	}
	chkPos(){
		const WindowHeight = $(window).height();
		const PageHeight = $(document).height();
		const ScrollTop = $(window).scrollTop();
		const footerHeight = $(this._options.fixedelement).height();
		const MoveTopBtn = WindowHeight + ScrollTop + footerHeight - PageHeight;
		const chkfooter = ScrollTop >= PageHeight - WindowHeight - footerHeight + this._BottomPos;
		let adjust;
		if(!!this._options.adjust){
			adjust = !window.matchMedia( '(max-width: '+ this._options.adjust.window +'px)' ).matches ? this._options.adjust.num : 0;
		}else{
			adjust = 0;
		}
		if(this._options.position == 'bottom'){
			adjust = adjust + this._$btn.height();
		}else if(this._options.position == 'center'){
			adjust = adjust + (this._$btn.height()/2);
		}
		if(chkfooter) {
			this._$btn.css({ bottom: MoveTopBtn - adjust });
		}else {
			this._$btn.css({ bottom: this._BottomPos });
		}
	}

}
//URLにハッシュがついていればその位置までアニメーションでスクロールする
class scrollAfterLoading{
	constructor(){
		const hash = window.location.hash;
		$('html, body').css('visibility', 'hidden');//一旦非表示に
		if(hash.length < 2){
		    $('html, body').css('visibility', '');
		}else{
		    setTimeout(function(){
		        $('html, body').scrollTop(0).css('visibility', '').delay(500).queue(function(){
		             var position = $(hash).offset().top;
		            $('html, body').animate({scrollTop:position}, 1000, 'swing').dequeue();
		        });
	        }, 0);
		}
	}

}


class spMenu{
	constructor(){
		this._btn = $('.header__btn');
		this._scrollpos;
		this._menuopen;

		this._btn.on('click', () => {
			this.ctrlMenu();
		});
		$('.header__sp').find('.header__link').pageScroll({fnc: ()=>{
			this.ctrlMenu();
		}});
	}
	ctrlMenu(){
		this._menuopen = this._btn.hasClass('active');
		if(!this._menuopen){//メニュー表示
			this._scrollpos = $(window).scrollTop();//スクロール位置を格納
			$('body').css('top', 0-this._scrollpos);
			$('body').addClass('activemenu');
		}else{//メニュー閉じる
			$('body').removeAttr('style');
			$('body').removeClass('activemenu');
			$(window).scrollTop(this._scrollpos)
		}
		this._btn.toggleClass('active');
		$('.header').midnight('refresh');//midnight.js対策
	}
}

(()=>{
	$('.pagetop').fixedElement({fixedelement: 'footer', position: 'center' }).find('#pagetop').pageScroll();
	$('.header').midnight().find('.header__link').pageScroll();
	new spMenu();
	new scrollAfterLoading();
})();

// Adobe Font
(function(d) {
  var config = {
    kitId: 'wcn8dzh',
    scriptTimeout: 3000,
    async: true
  },
  h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
})(document);